


const countryList = [
	{ countryCode: "AT", countryName: "Austria" },
	{ countryCode: "CZ", countryName: "Czechy" },
	{ countryCode: "DK", countryName: "Dania" },
	{ countryCode: "DE", countryName: "Niemcy" },
	{ countryCode: "NL", countryName: "Holandia" },
	{ countryCode: "PL", countryName: "Polska" },
	{ countryCode: "SK", countryName: "Słowacja" },
	// { countryCode: "SE", countryName: "Szwecja" },
];

export default countryList;
