import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../components/context/AppContext";

const Index = ( {cart, currency} ) => {

  const [cartData, setCartData] = useState(cart);
	//console.log(currency)
  useEffect(() => {
    // Update the local state when the cart prop changes
    setCartData(cart);
  }, [cart, currency]);



	return (
		<>
			{ cartData ? (
				<>
				{/*B2B account TODO:
					<p className="text-success">{authData?(authData.b2b === '1'
					? 'Congratulations! You get 10% B2B discount!'
					: null):("")}</p>*/}

					{/*Product Listing*/}
					<table className="table table-hover mb-0" style={{backgroundColor:'#fcfaf6'}}>
						<thead>
						{ /* eslint-disable */ }
						<tr className="woo-next-cart-head-container">
							<td className="woo-next-cart-heading-el mob-em" style={{backgroundColor:'#f7adb6'}}/>
							<th className="woo-next-cart-heading-el" style={{backgroundColor:'#f7adb6'}}>Produkt</th>
							<th className="woo-next-cart-heading-el" style={{backgroundColor:'#f7adb6'}}>Ilość</th>
							<th className="woo-next-cart-heading-el" style={{display:`${currency === 'pln' ? 'table-cell' : 'none'}`,backgroundColor:'#f7adb6'}}>Suma</th>
							<th className="woo-next-cart-heading-el" style={{display:`${currency === 'eur' ? 'table-cell' : 'none'}`,backgroundColor:'#f7adb6'}}>Suma</th>
						</tr>
						</thead>
						<tbody>
						{ cart.products.length && (
							cart.products.map( item => {
								// console.log(item)
								return (
									<tr className="woo-next-cart-item" key={ item.productId }>
										<td className="woo-next-cart-element mob-em">
											<img width="64" src={ item.image.sourceUrl } srcSet={ item.image.srcSet } alt={item.image.title}/>
										</td>
										<td className="woo-next-cart-element">{ item.name }&nbsp;
										{item.variation ?
											<>
											{item.variation.attributes.map((vars, i) => {
											return (
												<>
												{i<1?
													<small className="item-quantity">{vars.value}</small> :
													<>
													&nbsp;|&nbsp;<span className="item-quantity"><b>{vars.small}</b></span>
													</>
												}
												</>
											)
											})}
											</>
											:null
										}</td>
										<td className="woo-next-cart-element">{ item.qty }</td>
										<td className="woo-next-cart-element" style={{display:`${currency === 'pln' ? 'table-cell' : 'none'}`}}>{ item.totalPrice }</td>
										<td className="woo-next-cart-element" style={{display:`${currency === 'eur' ? 'table-cell' : 'none'}`}}>{ item.totalEuPrice }€</td>
									</tr>
								)
							} )
						) }
						{/*Total*/}
						<tr>
							<td className="woo-next-cart-heading-el mob-em"/>
							<td className="woo-next-checkout-total">Wysyłka</td>
							<td><small>Kurier</small></td>
							<td className="woo-next-checkout-total" style={{display:`${currency === 'pln' ? 'table-cell' : 'none'}`}}>{ cart.shippingTotal }</td>
							<td className="woo-next-checkout-total" style={{display:`${currency === 'eur' ? 'table-cell' : 'none'}`}}>{ cart.euShip }</td>
						</tr>

						<tr style={{display:`${currency === 'pln' ? 'table-row' : 'none'}`}}>
							<td className="woo-next-cart-heading-el mob-em"/>
							<td className="woo-next-checkout-total">Zniżka</td>
							<td><small>VAT</small></td>
							<td className="woo-next-checkout-total">-{ cart.discountTax }</td>
							<td style={{display:`${currency === 'eur' ? 'table-cell' : 'none'}`}}></td>
						</tr>

						<tr>
							<td className="woo-next-cart-heading-el mob-em"/>
							<td className="woo-next-checkout-total">Kupony</td>
							<td><small>Łącznie</small></td>
							<td className="woo-next-checkout-total pln" style={{display:`${currency === 'pln' ? 'table-cell' : 'none'}`}}>-{ cart.discountTotal }zł</td>
							<td className="woo-next-checkout-total eur" style={{display:`${currency === 'eur' ? 'table-cell' : 'none'}`}}>-{ cart.totalEuDiscount }€</td>
						</tr>

						{/*<tr>
							<td/>
							<td className="woo-next-checkout-total">Total tax</td>
							<td><small>Included</small></td>
							<td className="woo-next-checkout-total">{ cart.totalTax }</td>
							<td/>
						</tr>*/}

						<tr>
							<td className="woo-next-cart-heading-el mob-em"/>
							<td className="woo-next-checkout-total">Suma</td>
							<td className="woo-next-checkout-total"><b>{ cart.totalProductsCount }</b></td>
							<td className="woo-next-checkout-total pln" style={{display:`${currency === 'pln' ? 'table-cell' : 'none'}`}}><b>{ cart.totalProductsPrice }</b></td>
							<td className="woo-next-checkout-total eur" style={{display:`${currency === 'eur' ? 'table-cell' : 'none'}`}}><b>{ cart.totalEuWithShipping }</b></td>
						</tr>
						</tbody>
					</table>
				</>
			) : '' }
		</>
	)
};

export default Index;
