import React from "react";
import countryList from "./../country-list";
import Error from "./../error";

const Shipping = ({ input, handleOnChange }) => {
  // console.log(input)
  return (
    <React.Fragment>
      {/*Name*/}
      <div className="shipping-info">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="first-name2">
              Imię
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.firstName2}
              type="text"
              name="firstName2"
              className="form-control woo-next-checkout-input"
              id="first-name2"
            />
            <Error errors={input.errors} fieldName={"firstName"} />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="last-name2">
              Nazwisko
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.lastName2}
              type="text"
              name="lastName2"
              className="form-control woo-next-checkout-input"
              id="last-name2"
            />
            <Error errors={input.errors} fieldName={"lastName"} />
          </div>
        </div>

        {/* Company Name */}
        <div className="form-group">
          <label htmlFor="company-name2">Nazwa firmy</label>
          <input
            onChange={handleOnChange}
            value={input.company2}
            type="text"
            name="company2"
            className="form-control woo-next-checkout-input"
            id="company-name2"
          />
          <Error errors={input.errors} fieldName={"company"} />
        </div>
        {/* Country */}
        <div className="form-group">
          <label htmlFor="country-select2">
            Kraj
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          {/* eslint-disable */}
          <select
            onChange={handleOnChange}
            value={input.country2}
            name="country2"
            className="form-control woo-next-checkout-input"
            id="country-select2"
          >
            <option value="">Wybierz kraj...</option>
            {countryList.length &&
              countryList.map((country, index) => (
                <option key={`${country}-${index}`} value={country.countryCode}>
                  {country.countryName}
                </option>
              ))}
          </select>
          <Error errors={input.errors} fieldName={"country"} />
        </div>
        {/* Street Address */}
        <div className="form-group">
          <label htmlFor="street-address12">
            Adres
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <input
            type="text"
            onChange={handleOnChange}
            value={input.address12}
            name="address12"
            placeholder="Numer mieszkania, domu, nazwa ulicy"
            className="form-control woo-next-checkout-input"
            id="street-address12"
          />
          <Error errors={input.errors} fieldName={"address1"} />
          <br />
          <input
            type="text"
            onChange={handleOnChange}
            value={input.address22}
            name="address22"
            placeholder="Ciąg dalszy adresu (opcjonalnie)"
            className="form-control woo-next-checkout-input"
            id="address-22"
          />
        </div>
        {/* Town/City */}
        <div className="form-group">
          <label htmlFor="city2">
            Miasto
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <input
            onChange={handleOnChange}
            value={input.city2}
            type="text"
            name="city2"
            className="form-control woo-next-checkout-input"
            id="city2"
          />
          <Error errors={input.errors} fieldName={"city"} />
        </div>
        {/* County */}
        <div className="form-group">
          <label htmlFor="state2">
            Województwo
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <input
            onChange={handleOnChange}
            value={input.state2}
            type="text"
            name="state2"
            className="form-control woo-next-checkout-input"
            id="state2"
          />
          <Error errors={input.errors} fieldName={"state"} />
        </div>
        <div className="form-row">
          {/* Post Code */}
          <div className="form-group col-md-6">
            <label htmlFor="post-code2">
              Kod pocztowy
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.postcode2}
              type="text"
              name="postcode2"
              className="form-control woo-next-checkout-input"
              id="post-code2"
            />
          <Error errors={input.errors} fieldName={"postcode"} />
          </div>
          {/*Phone*/}
          <div className="form-group col-md-6">
            <label htmlFor="phone2">
              Numer telefonu
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.phone2}
              type="text"
              name="phone2"
              className="form-control woo-next-checkout-input"
              id="phone2"
            />
            <Error errors={input.errors} fieldName={"phone"} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Shipping;
